

html, body, #root {
    width: 100%;
    height: 100%;
    background-color: #2D2D77;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

#react-logo {
    max-width: 100%;
    height: 90%;
}

.app {
    &__title {
        height: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-weight: bold;

        /*& > div {

            height: 90%!important;
            display: flex;
            justify-content: center;
            align-items: center;

            & svg {
                width: 70%!important;
            }
        }*/
    }

    &__loader {
        width: 100%;
        height: 100%;
        position: fixed;
        background-color: rgba(0, 0, 0, 0.555);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 7000 ; 
        cursor: wait;

        & svg {
            width: 250px;
            animation: 1s infinite loading;
            color: white;
        }

        & > div {
            position: relative;
            & div {
                position: absolute;
                border-radius: 50%;
                left: 50%;
                top: 50%;
                transform: translateX(-50%) translateY(-50%);


                &:nth-child(1) {
                    width: 120px;
                    height: 120px;
                    border-top: 5px solid #2D2D77;
                    border-left: 5px solid #2D2D77;
                    animation: 1s infinite loading;
                }

                &:nth-child(2) {
                    width: 85px;
                    height: 85px;
                    border-left: 4px solid #a9a9f4;
                    border-bottom: 4px solid #a9a9f4;
                    animation: 1s infinite loading;
                }

                &:nth-child(3) {
                    width: 50px;
                    height: 50px;
                    border-bottom: 3px solid white;
                    border-right: 3px solid white;
                    animation: 1s infinite loading;
                }
                &:nth-child(4) {
                    width: 20px;
                    height: 20px;
                    border-left: 2px solid #2D2D77;
                    border-top: 2px solid #2D2D77;
                    animation: 1s infinite loading;
                }
            }
        }


    }

    &__add {
        position: fixed;
        height: 50px;
        width: 50px;
        background-color: white;
        bottom: 15px;
        right: 15px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        cursor: pointer;

        &:hover div {
            transform: scale(0);
        }

        & div {
            background-color: #a7a7f562;
            width: 100px;
            height: 100px;
            position: absolute;
            border-radius: 50%;
            transform: scale(1);
            transition: all 500ms;
        }


        & svg {
            color: #2D2D77;
            transform: scale(2);

        }
    }

    &__minus {
        position: fixed;
        height: 50px;
        width: 50px;
        background-color: white;
        bottom: 15px;
        left: 15px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        cursor: pointer;

        &:hover div {
            transform: scale(0);
        }

        & div {
            background-color: #a7a7f562;
            width: 100px;
            height: 100px;
            position: absolute;
            border-radius: 50%;
            transform: scale(1);
            transition: all 500ms;
        }


        & svg {
            color: #2D2D77;
            transform: scale(2);

        }
    }
}

.statusRequest {
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.555);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 7000 ; 
    cursor: pointer;

    & > div {
        width: 80%;
        height: 20%;
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        align-items: center;
        border-radius: 5px;

    }

    &__svg {
        & svg {
            transform: scale(3);
            color: white;
        }


    }

    &__content {
        font-size: 18px;
        font-weight: bold;
        color: white;
    }
}

//








.visualizer {
    background-color: #0b0b1bb4;
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2000;
    & img {
        max-width: 90%;
    }

    & .video-responsive {
        width: 80%;
        height: 50%;
      
      }

      &__link {
        border-radius: 5px;
        background-color: white;
        display: flex;
        flex-direction: row;
        height: 40px;
        margin-bottom: 10px;
        cursor: pointer;
        width: 60%;
        margin-top: 10px;
        
        & > div {
            width: 15%;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            background: linear-gradient(90deg, #999999 0%, #fff 20%);
            display: flex;
            justify-content: center;
            align-items: center;
            padding-left: 8px;

            
            & svg{
                transform: scale(1);

            }
        }

        & a, p{
            width: calc(85% - 15px);
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            color: black;
            text-align: end;
            margin: 0;
            font-size: 12px;
        }
      } 

      &__embedCode {
          width: 80%;
          height: 100px;
          transform: none!important;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          & label {
              color: white;
              margin-bottom: 5px;
          }

          & input {
              width: 300px;
          }
      }

      &__close {
          position: absolute;
          top: 15px;
          right: 15px;
          cursor: pointer;

          & svg {
              color: #a7a7f5;
              transform: scale(2);
          }
      }
      
}



//FONCTIONNALITY ADD
.app {
 
}



