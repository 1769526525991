/*@keyframes loading {
    0% {
        transform: scale(0.9) rotate(0deg);
    }
    50% {
        transform: scale(1) rotate(360deg);
    }
    100% {
        transform: scale(0.9) rotate(360deg);
    }
}*/

@keyframes loading {
    0% {
        transform: translateX(-50%) translateY(-50%) scale(0.9) rotate(0deg);
    }

    100% {
        transform: translateX(-50%) translateY(-50%) scale(0.9) rotate(360deg);
    }
}