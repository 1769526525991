.homepage {
    width: 90%;
    height: 90%;

    &__search {
        border-bottom: 1px solid #A9A9F4;
        margin-bottom: 15px;
        height: 50px;
        display: flex;
        justify-content: center;

        & > div{
            width: 70%;
            text-align: center;
            background-color: white;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            height: 30px;                
            border-radius: 15px;

            & input {
                width: 85%;
                height: 100%;
                text-align: center;

                border: none;
                border-top-left-radius: 15px;
                border-bottom-left-radius: 15px;
                background-color: transparent;

                &:focus {
                    outline: none;
                }
            }

            & > div {
                width: 15%;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer ; 
                
                & svg {
                    transform: scale(1.1);
                    color: #2d2d77;
                }
            }
        }

    }

    &__list {
        height: calc(100% - 65px);
        overflow-y: scroll;
        overflow-x: hidden;
        & > div {

            margin-bottom: 10px;
        }

        &__container {


            & #noData {
                background-color: transparent!important;
                font-size: 20px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                margin-top: 30px;
                height: 200px;
                color: white;

                & svg {
                    transform: scale(2);
                }
            }


            & > div {
                border-radius: 5px;
                background-color: white;
                display: flex;
                flex-direction: row;
                height: 60px;
                margin-bottom: 10px;
                cursor: pointer;

                & > div {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position : relative ; 

                    & .cancelTuto {
                        position: absolute;
                        width: 40px;
                        height: 40px;
                        background-color: rgba(255, 60, 0, 0.7);
                        border-radius: 50%;
                        display: flex; 
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        left: 50%;
                        transform: translateX(-50%);

                        &:hover {
                            background-color: red;
                        }

                        & svg {
                            color: white;
                            width: 50px;
                            height: 50px;
                        }
                    }

                    &:first-child {
                        width: 90%;
                        height: 100%;
                        flex-direction: column;

                        & p {
                            margin: 0;
                            padding-left: 8px;
                            padding-right: 8px;
                            text-align: center;

                            &:last-child{
                                color: #1aa9ca;
                                font-weight: normal;
                            }
                        }



                    }
                    &:last-child{
                        width: 10%;
                        border-top-right-radius: 5px;
                        border-bottom-right-radius: 5px;
                        background: linear-gradient(90deg, #999999 0%, #fff 20%);
                        
                        & svg{
                            width: 15px;
                            margin-left: 5px;
                        }
                    }
                }
            }
        }


    }

}