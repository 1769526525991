
.save {
    position: fixed;
    z-index: 3000;
    width: 100%;
    height: 100%;
    background-color: rgba(37, 37, 37, 0.644);
    display: flex;
    justify-content: center;
    align-items: center;

    & > div {
        width: 90%;
        height: 300px;
        background-color: white;
        border-radius: 5px;
    }

    &__header {
        height: 30px;

        display: flex;
        justify-content: flex-end;
        align-items: center;
        border-bottom: 2px solid #2d2d776e;
        & > div {
            width: 30%;
            height: 100%;

            display: flex;
            justify-content: flex-end ; 
            align-items: center;

            & > div {
                background-color: #2D2D77;
                border-radius: 50%;
                width: 20px;
                height: 20px;
                margin : 0px 5px ; 
                display: flex;
                justify-content: center;
                align-items: center;

                &:last-child {
                    background-color: #ff4800;
                    cursor: pointer;
                    & svg {
                        color: white;
                        width: 90%;
                    }
                }


            }
        }
    }

    &__content {
        width: 100%;
        height: calc(100% - 90px);
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;

        & svg {
            transform: scale(2.5);
            color : #2d2d77;
            margin-top: 20px;
        }

        & p {
            margin: 0;
            color: #2D2D77;
            width: 80%;
            font-size: 16px;
            text-align: center;
            font-weight: bold;
        }
    }


    &__buttons {
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: space-around;
        align-items: center;

        & div {
            width: 45%;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            color: white;
            cursor: pointer;
            font-size: 14px;
            padding-bottom: 4px;

            &:first-child {
                background-color: #7E7EFF;
            }

            &:last-child {
                background-color: #ff4800;
            }
        }
    }

}