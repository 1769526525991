/*@keyframes loading {
    0% {
        transform: scale(0.9) rotate(0deg);
    }
    50% {
        transform: scale(1) rotate(360deg);
    }
    100% {
        transform: scale(0.9) rotate(360deg);
    }
}*/
@keyframes loading {
  0% {
    transform: translateX(-50%) translateY(-50%) scale(0.9) rotate(0deg);
  }
  100% {
    transform: translateX(-50%) translateY(-50%) scale(0.9) rotate(360deg);
  }
}
html, body, #root {
  width: 100%;
  height: 100%;
  background-color: #2D2D77;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

#react-logo {
  max-width: 100%;
  height: 90%;
}

.app__title {
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  /*& > div {

      height: 90%!important;
      display: flex;
      justify-content: center;
      align-items: center;

      & svg {
          width: 70%!important;
      }
  }*/
}
.app__loader {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.555);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 7000;
  cursor: wait;
}
.app__loader svg {
  width: 250px;
  animation: 1s infinite loading;
  color: white;
}
.app__loader > div {
  position: relative;
}
.app__loader > div div {
  position: absolute;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.app__loader > div div:nth-child(1) {
  width: 120px;
  height: 120px;
  border-top: 5px solid #2D2D77;
  border-left: 5px solid #2D2D77;
  animation: 1s infinite loading;
}
.app__loader > div div:nth-child(2) {
  width: 85px;
  height: 85px;
  border-left: 4px solid #a9a9f4;
  border-bottom: 4px solid #a9a9f4;
  animation: 1s infinite loading;
}
.app__loader > div div:nth-child(3) {
  width: 50px;
  height: 50px;
  border-bottom: 3px solid white;
  border-right: 3px solid white;
  animation: 1s infinite loading;
}
.app__loader > div div:nth-child(4) {
  width: 20px;
  height: 20px;
  border-left: 2px solid #2D2D77;
  border-top: 2px solid #2D2D77;
  animation: 1s infinite loading;
}
.app__add {
  position: fixed;
  height: 50px;
  width: 50px;
  background-color: white;
  bottom: 15px;
  right: 15px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
}
.app__add:hover div {
  transform: scale(0);
}
.app__add div {
  background-color: #a7a7f562;
  width: 100px;
  height: 100px;
  position: absolute;
  border-radius: 50%;
  transform: scale(1);
  transition: all 500ms;
}
.app__add svg {
  color: #2D2D77;
  transform: scale(2);
}
.app__minus {
  position: fixed;
  height: 50px;
  width: 50px;
  background-color: white;
  bottom: 15px;
  left: 15px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
}
.app__minus:hover div {
  transform: scale(0);
}
.app__minus div {
  background-color: #a7a7f562;
  width: 100px;
  height: 100px;
  position: absolute;
  border-radius: 50%;
  transform: scale(1);
  transition: all 500ms;
}
.app__minus svg {
  color: #2D2D77;
  transform: scale(2);
}

.statusRequest {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.555);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 7000;
  cursor: pointer;
}
.statusRequest > div {
  width: 80%;
  height: 20%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
}
.statusRequest__svg svg {
  transform: scale(3);
  color: white;
}
.statusRequest__content {
  font-size: 18px;
  font-weight: bold;
  color: white;
}

.visualizer {
  background-color: #0b0b1bb4;
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}
.visualizer img {
  max-width: 90%;
}
.visualizer .video-responsive {
  width: 80%;
  height: 50%;
}
.visualizer__link {
  border-radius: 5px;
  background-color: white;
  display: flex;
  flex-direction: row;
  height: 40px;
  margin-bottom: 10px;
  cursor: pointer;
  width: 60%;
  margin-top: 10px;
}
.visualizer__link > div {
  width: 15%;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background: linear-gradient(90deg, #999999 0%, #fff 20%);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 8px;
}
.visualizer__link > div svg {
  transform: scale(1);
}
.visualizer__link a, .visualizer__link p {
  width: calc(85% - 15px);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: black;
  text-align: end;
  margin: 0;
  font-size: 12px;
}
.visualizer__embedCode {
  width: 80%;
  height: 100px;
  transform: none !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.visualizer__embedCode label {
  color: white;
  margin-bottom: 5px;
}
.visualizer__embedCode input {
  width: 300px;
}
.visualizer__close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}
.visualizer__close svg {
  color: #a7a7f5;
  transform: scale(2);
}

.homepage {
  width: 90%;
  height: 90%;
}
.homepage__search {
  border-bottom: 1px solid #A9A9F4;
  margin-bottom: 15px;
  height: 50px;
  display: flex;
  justify-content: center;
}
.homepage__search > div {
  width: 70%;
  text-align: center;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 30px;
  border-radius: 15px;
}
.homepage__search > div input {
  width: 85%;
  height: 100%;
  text-align: center;
  border: none;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  background-color: transparent;
}
.homepage__search > div input:focus {
  outline: none;
}
.homepage__search > div > div {
  width: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.homepage__search > div > div svg {
  transform: scale(1.1);
  color: #2d2d77;
}
.homepage__list {
  height: calc(100% - 65px);
  overflow-y: scroll;
  overflow-x: hidden;
}
.homepage__list > div {
  margin-bottom: 10px;
}
.homepage__list__container #noData {
  background-color: transparent !important;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 30px;
  height: 200px;
  color: white;
}
.homepage__list__container #noData svg {
  transform: scale(2);
}
.homepage__list__container > div {
  border-radius: 5px;
  background-color: white;
  display: flex;
  flex-direction: row;
  height: 60px;
  margin-bottom: 10px;
  cursor: pointer;
}
.homepage__list__container > div > div {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.homepage__list__container > div > div .cancelTuto {
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: rgba(255, 60, 0, 0.7);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  left: 50%;
  transform: translateX(-50%);
}
.homepage__list__container > div > div .cancelTuto:hover {
  background-color: red;
}
.homepage__list__container > div > div .cancelTuto svg {
  color: white;
  width: 50px;
  height: 50px;
}
.homepage__list__container > div > div:first-child {
  width: 90%;
  height: 100%;
  flex-direction: column;
}
.homepage__list__container > div > div:first-child p {
  margin: 0;
  padding-left: 8px;
  padding-right: 8px;
  text-align: center;
}
.homepage__list__container > div > div:first-child p:last-child {
  color: #1aa9ca;
  font-weight: normal;
}
.homepage__list__container > div > div:last-child {
  width: 10%;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background: linear-gradient(90deg, #999999 0%, #fff 20%);
}
.homepage__list__container > div > div:last-child svg {
  width: 15px;
  margin-left: 5px;
}

.add {
  position: fixed;
  z-index: 3000;
  width: 100%;
  height: 100%;
  background-color: rgba(37, 37, 37, 0.644);
  display: flex;
  justify-content: center;
  align-items: center;
}
.add > div {
  width: 90%;
  height: 55%;
  background-color: white;
  border-radius: 5px;
}
.add__header {
  height: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-bottom: 2px solid #2d2d776e;
}
.add__header > div {
  width: 30%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.add__header > div > div {
  background-color: #2D2D77;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin: 0px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.add__header > div > div:last-child {
  background-color: #ff4800;
  cursor: pointer;
}
.add__header > div > div:last-child svg {
  color: white;
  width: 90%;
}
.add__form {
  width: 100%;
  height: calc(100% - 110px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.add__form > div {
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.add__form > div label {
  width: 90%;
  text-align: center;
  height: 40%;
  display: flex;
  align-items: center;
  color: #2D2D77;
  text-shadow: 0px 0px 5px #8181db;
}
.add__form > div input {
  width: 90%;
  height: 30%;
  border-bottom: solid 1px #2D2D77;
  border-top: none;
  border-left: none;
  border-right: none;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}
.add__form > div input:focus {
  outline: none;
}
.add__buttons {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.add__buttons > div {
  width: 60%;
  height: 50%;
  display: flex;
  border-radius: 5px;
  background-color: #7E7EFF;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  cursor: pointer;
  margin-top: 10px;
  padding-bottom: 2px;
  font-size: 14px;
}
.add__buttons > div > div {
  width: 15%;
  height: calc(100% + 2px);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background: linear-gradient(90deg, #7E7EFF 0%, #2d2d77 20%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.add__buttons > div p {
  width: 85%;
  margin: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: white;
}
.add__buttons > div svg {
  color: white;
}

.app__check {
  background-color: #2a4601;
  width: 100%;
  height: 30px;
  right: 0;
}
.app__check svg {
  color: #2D2D77;
}

.detailTuto {
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.detailTuto__return {
  width: 90%;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #A9A9F4;
  margin-bottom: 15px;
  height: 50px;
}
.detailTuto__return > div {
  display: flex;
  flex-direction: row;
  height: 35px;
  background-color: #e3935a;
  width: 100%;
  border-radius: 5px;
  margin-bottom: 15px;
  cursor: pointer;
}
.detailTuto__return > div > div:first-child {
  width: 10%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(-90deg, #a86637 0%, #e3935a 20%);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.detailTuto__return > div > div:last-child {
  width: 90%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2D2D77;
}
.detailTuto__return > div > div svg {
  transform: rotate(180deg);
  width: 15px;
  margin-right: 5px;
}
.detailTuto__container {
  background-color: white;
  width: 90%;
  height: calc(100% - 65px);
  border-radius: 8px;
  position: relative;
}
.detailTuto__container__element {
  height: calc(100% - 50px);
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}
.detailTuto__container__element__title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: solid 1.5px #2D2D77;
  background-color: #7E7EFF;
  height: 50px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.detailTuto__container__element__title input {
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
  color: #FFFFFF;
  text-align: center;
}
.detailTuto__container__element__title input:first-child {
  border-bottom: solid 1px white;
  padding-bottom: 5px;
  font-size: 16px;
}
.detailTuto__container__element__title input:last-child {
  padding-top: 5px;
  font-size: 12px;
  color: #2D2D77;
}
.detailTuto__container__element__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.detailTuto__container__element__content input, .detailTuto__container__element__content textarea {
  border: none;
  resize: vertical;
}
.detailTuto__container__element__content input:focus, .detailTuto__container__element__content textarea:focus {
  outline: none;
}
.detailTuto__container__element__content textarea {
  text-align: justify;
}
.detailTuto__container__element__content .subImage {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.detailTuto__container__element__content .subImage__delete {
  position: absolute;
  width: 60px;
  height: 60px;
  background-color: rgba(255, 60, 0, 0.7);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.detailTuto__container__element__content .subImage__delete svg {
  color: white;
  width: 50px;
  height: 50px;
}
.detailTuto__container__element__content textarea {
  resize: none;
  width: 100%;
  min-height: 50px;
  padding: 5px;
  overflow: hidden;
  box-sizing: border-box;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}
.detailTuto__container__element__content > div {
  display: flex;
  flex-direction: column;
  width: 95%;
  position: relative;
  margin-top: 20px;
}
.detailTuto__container__element__content .closeKebabMenu {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(59, 59, 59, 0.6);
  z-index: 1999;
  top: 0;
  left: 0;
  cursor: default !important;
}
.detailTuto__container__element__content .kebabMenu {
  position: absolute;
  right: 0;
  top: 5px;
}
.detailTuto__container__element__content .kebabMenu div {
  cursor: pointer;
}
.detailTuto__container__element__content .kebabMenu div svg {
  height: 20px;
}
.detailTuto__container__element__content .kebabMenu div svg circle {
  fill: #2D2D77;
}
.detailTuto__container__element__content .kebabMenu ul {
  position: absolute;
  top: 10px;
  right: 0;
  background-color: #2D2D77;
  border: 1px solid #7E7EFF;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  box-shadow: -3px 3px 10px 0px #000;
  width: 250px;
  list-style-type: none;
  padding: 0;
  z-index: 2000;
}
.detailTuto__container__element__content .kebabMenu ul li {
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: end;
  padding-right: 10px;
  cursor: pointer;
  color: white;
  border-bottom: solid 1px #7E7EFF;
  font-size: 14px;
}
.detailTuto__container__element__content .kebabMenu ul li:last-child {
  border-bottom: none;
}
.detailTuto__container__element__content #add {
  margin: 10px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.detailTuto__container__element__content #add > div:first-child {
  background-color: #2d2d77;
  border: 2px solid #a9a9f4;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
}
.detailTuto__container__element__content #add > div:first-child svg {
  transform: scale(2);
  color: #a9a9f4;
}
.detailTuto__container__element__content #add > div:first-child:hover + div:last-child {
  transform: scale(1);
}
.detailTuto__container__element__content #add > div:last-child {
  transform: scale(0);
  transition: all 220ms;
  margin: 10px 0px;
  font-size: 18px;
}
.detailTuto__container__element__content .title {
  text-transform: uppercase;
  color: #2D2D77;
  text-shadow: 0px 0px 5px #8181db;
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.detailTuto__container__element__content img {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.detailTuto__container__link {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 50px;
  cursor: pointer;
  border-top: solid 1.5px #2D2D77;
  align-items: center;
}
.detailTuto__container__link div {
  height: 100%;
}
.detailTuto__container__link div svg {
  width: 50px;
  height: 50px;
}

.image__preview {
  position: fixed;
  background-color: #0b0b1bb4;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image__preview img {
  width: 90%;
  max-height: 100%;
}

.addImage {
  position: fixed;
  z-index: 3000;
  width: 100%;
  height: 100%;
  background-color: rgba(37, 37, 37, 0.644);
  display: flex;
  justify-content: center;
  align-items: center;
}
.addImage > div {
  width: 90%;
  height: 55%;
  background-color: white;
  border-radius: 5px;
}
.addImage__header {
  height: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-bottom: 2px solid #2d2d776e;
}
.addImage__header > div {
  width: 30%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.addImage__header > div > div {
  background-color: #2D2D77;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin: 0px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.addImage__header > div > div:last-child {
  background-color: #ff4800;
  cursor: pointer;
}
.addImage__header > div > div:last-child svg {
  color: white;
  width: 90%;
}
.addImage__content {
  width: 100%;
  height: calc(100% - 100px);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.addImage__content--preview {
  width: 80%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.addImage__content--preview img {
  height: 100%;
}
.addImage__content--input {
  background-color: #2d2d77;
  width: 80%;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border: 1px solid #a9a9f4;
  border-radius: 10px;
  cursor: pointer;
}
.addImage__content--input svg {
  transform: scale(1.5);
  color: #a9a9f4;
}
.addImage__content--input p {
  margin: 0;
  color: #a9a9f4;
}
.addImage__buttons {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.addImage__buttons > div {
  width: 60%;
  height: 50%;
  display: flex;
  border-radius: 5px;
  background-color: #7E7EFF;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  cursor: pointer;
  padding-bottom: 2px;
  font-size: 14px;
}
.addImage__buttons > div > div {
  width: 15%;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background: linear-gradient(90deg, #7E7EFF 0%, #2d2d77 20%);
  height: calc(100% + 2px);
  display: flex;
  padding-left: 5px;
  justify-content: center;
  align-items: center;
}
.addImage__buttons > div p {
  width: 85%;
  margin: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: white;
}
.addImage__buttons > div svg {
  color: white;
}

.save {
  position: fixed;
  z-index: 3000;
  width: 100%;
  height: 100%;
  background-color: rgba(37, 37, 37, 0.644);
  display: flex;
  justify-content: center;
  align-items: center;
}
.save > div {
  width: 90%;
  height: 300px;
  background-color: white;
  border-radius: 5px;
}
.save__header {
  height: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-bottom: 2px solid #2d2d776e;
}
.save__header > div {
  width: 30%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.save__header > div > div {
  background-color: #2D2D77;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin: 0px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.save__header > div > div:last-child {
  background-color: #ff4800;
  cursor: pointer;
}
.save__header > div > div:last-child svg {
  color: white;
  width: 90%;
}
.save__content {
  width: 100%;
  height: calc(100% - 90px);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.save__content svg {
  transform: scale(2.5);
  color: #2d2d77;
  margin-top: 20px;
}
.save__content p {
  margin: 0;
  color: #2D2D77;
  width: 80%;
  font-size: 16px;
  text-align: center;
  font-weight: bold;
}
.save__buttons {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.save__buttons div {
  width: 45%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  color: white;
  cursor: pointer;
  font-size: 14px;
  padding-bottom: 4px;
}
.save__buttons div:first-child {
  background-color: #7E7EFF;
}
.save__buttons div:last-child {
  background-color: #ff4800;
}

