

.addImage {
    position: fixed;
    z-index: 3000;
    width: 100%;
    height: 100%;
    background-color: rgba(37, 37, 37, 0.644);
    display: flex;
    justify-content: center;
    align-items: center;

    & > div {
        width: 90%;
        height: 55%;
        background-color: white;
        border-radius: 5px;
    }

    &__header {
        height: 30px;

        display: flex;
        justify-content: flex-end;
        align-items: center;
        border-bottom: 2px solid #2d2d776e;
        & > div {
            width: 30%;
            height: 100%;

            display: flex;
            justify-content: flex-end ; 
            align-items: center;

            & > div {
                background-color: #2D2D77;
                border-radius: 50%;
                width: 20px;
                height: 20px;
                margin : 0px 5px ; 
                display: flex;
                justify-content: center;
                align-items: center;

                &:last-child {
                    background-color: #ff4800;
                    cursor: pointer;
                    & svg {
                        color: white;
                        width: 90%;
                    }
                }


            }
        }
    }

    &__content {
        width: 100%;
        height: calc(100% - 100px);
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;

        &--preview {
            width: 80%;
            height: 150px;
            display: flex;
            justify-content: center;
            align-items: center;

            & img {
                height: 100%;
            }

        }

        &--input {
            background-color: #2d2d77;
            width: 80%;
            height: 100px;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            border: 1px solid #a9a9f4;
            border-radius: 10px;
            cursor: pointer;

            & svg {
                transform: scale(1.5);
                color: #a9a9f4;
            }

            & p {
                margin: 0;
                color: #a9a9f4;
            }

        }
    }

    &__buttons {
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;


        & > div {
            width: 60%;
            height: 50%;
            display: flex;
            border-radius: 5px;
            background-color: #7E7EFF;
            display: flex;
            flex-direction: row;
            margin-bottom: 10px;
            cursor: pointer;
            padding-bottom: 2px;
            font-size: 14px;


            & > div {
                width: 15%;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                background: linear-gradient(90deg, #7E7EFF 0%, #2d2d77 20%);
                height: calc(100% + 2px);
                display: flex;
                padding-left: 5px;
                justify-content: center;
                align-items: center;
            }

            & p {
                width: 85%;
                margin: 0;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                color: white;
            }

            & svg {
                color: white;
            }
            

        }
    }

}
