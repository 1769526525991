
.detailTuto {
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;



    &__return {
        width: 90%;
        display: flex;
        justify-content: center;
        border-bottom: 1px solid #A9A9F4;
        margin-bottom: 15px;
        height: 50px;
        & > div {
            display: flex;
            flex-direction: row;
            height: 35px;
            background-color: #e3935a;
            width: 100%;
            border-radius: 5px;
            margin-bottom: 15px;
            cursor: pointer;


            & > div {
                &:first-child{
                    width: 10%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: linear-gradient(-90deg, #a86637 0%, #e3935a 20%);
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                }
                
                &:last-child{
                    width: 90%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #2D2D77;
                }

                & svg {
                    transform : rotate(180deg);
                    width: 15px;
                    margin-right: 5px;
                }
            }
        }
    }

    &__container{
        background-color: white;
        width: 90%;
        height: calc(100% - 65px);
        border-radius: 8px;
        position: relative;


        
        &__element {
            height: calc(100% - 50px);
            width: 100%;
            overflow-y : scroll;
            overflow-x: hidden;



            &__title{
                display: flex;
                flex-direction: column;
                justify-content: center;
                border-bottom: solid 1.5px #2D2D77;
                background-color: #7E7EFF;
                height: 50px;
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
                padding-top: 5px;
                padding-bottom: 5px;

                & input {
                    width: 100%;
                    height: 100%;
                    background-color: transparent;
                    border: none;
                    color: #FFFFFF;
                    text-align: center;


                    &:first-child {
                       border-bottom: solid 1px white;
                       padding-bottom: 5px;
                       font-size: 16px;

                    }

                    &:last-child {
                        //font-style: italic;
                        padding-top: 5px;
                        font-size: 12px;
                        color: #2D2D77;
                        
                    }
                }
            }

            &__content {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;

                & input, textarea {
                    border: none;
                    resize: vertical;

                    &:focus {
                        outline: none;
                    }
                }

                & textarea {
                    text-align: justify;
                }

                & .subImage {
                    margin-top: 10px;
                    margin-bottom: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;

                    &__delete {
                        position: absolute;
                        width: 60px;
                        height: 60px;
                        background-color: rgba(255, 60, 0, 0.7);
                        border-radius: 50%;
                        display: flex; 
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;

                        & svg {
                            color: white;
                            width: 50px;
                            height: 50px;
                        }
                    }

                }

                & img {

                }

                & textarea {
                    resize: none;
                    width: 100%;
                    min-height: 50px;
                    padding: 5px;
                    overflow: hidden;
                    box-sizing: border-box;
                    font-size: 14px;
                    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
                }

                & > div {
                    display: flex;
                    flex-direction: column;
                    width: 95%;
                    position: relative;
                    margin-top: 20px;
                }

                .closeKebabMenu {
                    width: 100%;
                    height: 100%;
                    position: fixed;
                    background-color: rgba(59, 59, 59, 0.6);
                    z-index: 1999;
                    top: 0;
                    left: 0;
                    cursor: default!important;
                }

                .kebabMenu {
                    position: absolute;
                    right: 0;
                    top: 5px;

                    & div {
                        cursor: pointer;
                        & svg {
                            height: 20px;

                            & circle {
                                fill: #2D2D77;
                            }

                        }
                    }
                
                    & ul {
                        position: absolute;
                        top: 10px;
                        right: 0;
                        background-color: #2D2D77;
                        border: 1px solid #7E7EFF;
                        border-top-left-radius: 8px;
                        border-bottom-left-radius: 8px;
                        box-shadow: -3px 3px 10px 0px #000;
                        width: 250px;
                        list-style-type: none;
                        padding: 0;
                        z-index: 2000;
                        
                        
                        & li {
                            height: 50px;
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;
                            text-align: end;
                            padding-right: 10px;
                            cursor: pointer;
                            color: white;
                            border-bottom: solid 1px #7E7EFF;
                            font-size: 14px;

                            &:last-child {
                                border-bottom: none;
                            }
                        }

                    }
                }

                & #add {
                    margin: 10px 0px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    & > div {
                        &:first-child {
                            background-color: #2d2d77;
                            border: 2px solid #a9a9f4;
                            
                            width: 60px;
                            height: 60px;
                            display: flex;
                            justify-content: center;
                            align-items: center; 
                            border-radius: 50%;
                            cursor: pointer;
                            & svg {
                                transform: scale(2);
                                color: #a9a9f4;
                            }

                            &:hover + div:last-child {
                                transform: scale(1);
                            }
                        }

                        &:last-child {
                            transform: scale(0);
                            transition: all 220ms;
                            margin : 10px 0px ; 
                            font-size: 18px;
                        }
                    }
                }

                & .title {
                    text-transform: uppercase;
                    color: #2D2D77;
                    text-shadow: 0px 0px 5px #8181db;
                    font-size: 16px;
                    margin-top: 5px;
                    margin-bottom: 5px;
                }



                & img {
                    width: 90%;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }

        &__link{
            display: flex;
            justify-content: center;
            width: 100%;
            height: 50px;
            cursor: pointer;
            border-top: solid 1.5px #2D2D77;
            align-items: center;

            & div {
                height: 100%;
                & svg {
                    width: 50px;
                    height: 50px;
                }
            }
        }


    }
}


.image__preview {
    position: fixed;
    background-color: #0b0b1bb4;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 2000;
    display: flex;
    justify-content: center;
    align-items: center;

    & img {
        width: 90%;
        max-height: 100%;
    }
}